<template>
  <div class="content">
    <div class="add">
      <el-button class="tb-button" type="primary" @click="add">
        <i class="tb-icon-plus"/>添加子类目
      </el-button>
    </div>
    <div class="tablebox">
      <el-table
        :data="tableData"
        class="tb-table"
        @row-dblclick="toDetail"
      >
        <el-table-column prop="id" label="类目ID"/>
        <el-table-column prop="categoryName" label="子类目名称"/>
        <el-table-column prop="date" label="创建时间"/>
        <el-table-column prop="lastModifier" label="创建用户"/>
        <el-table-column prop="status" label="状态"/>
        <el-table-column prop="sort" label="排序"/>
        <el-table-column label="操作" min-width="120px">
          <template slot-scope="scope">
            <!-- <router-link :to="`/categroy/third?pid=${scope.row.id}`" class="edit">叶子类目管理</router-link> -->
            <span class="edit" @click="toChild(scope.row)">叶子类目管理</span>
            <el-divider direction="vertical"></el-divider>
            <span class="edit" @click="toDetail(scope.row)">编辑</span>
            <el-divider direction="vertical"></el-divider>
            <span class="edit" @click="deleteItem(scope.row)">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        class="tb-pagination"
        layout="prev, pager, next"
        :current-page.sync="page.no + 1"
        :page-size="page.size"
        :total="page.total"
        @current-change="pageChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CategroySecond',
  data() {
    return {
      pid: null,
      search: {
        name: ''
      },
      tableData: [],
      page: {
        no: 0,
        size: 10,
        total: 0
      }
    }
  },
  mounted() {
    let path = this.$route.fullPath
    if (path.indexOf('/') === 0) path = path.substring(1)
    const currentPage = {
      link: path,
      name: '子类目列表'
    }
    this.$store.commit('changeRoute', currentPage)
    this.pid = this.$route.query.pid || null
    if (this.pid) {
      this.getList()
    } else {
      this.$router.push('/categroy/first')
    }
  },
  methods: {
    async getList() {
      let {no, size} = this.page
      let {name} = this.search
      const params = {
        pid: this.pid,
        name,
        page_size: size,
        page_no: no
      }
      const url = 'admin/category/list'
      const data = await this.$https.get(url, {params})
      if (!data) return
      data.list.forEach(item => {
        item.imgurl = this.$img(item.img)
        item.date = this.$dayjs(item.createdAt).format('YYYY-MM-DD')
        item.status = item.status === 1 ? '有效' : '无效'
      })
      this.page.total = data.total_count
      this.tableData = data.list
    },
    onSearch() {
      this.page.no = 0
      this.getList()
    },
    add() {
      this.$store.commit('setCategroy', {_type: 2})
      this.$router.push(`/categroy/add?pid=${this.pid}&type=2`)
    },
    deleteItem(item) {
      this.$confirm('是否删除此类目?', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const url = 'admin/category/edit'
        const params = Object.assign({}, item, {status: -1})
        const data = await this.$https.post(url, params)
        if (data) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getList()
        }
      }).catch(() => {})
    },
    pageChange(no) {
      this.page.no = no - 1
      this.getList()
    },
    toChild(row) {
      this.$store.commit('setCategroy', {...row, _type: 2})
      this.$router.push(`/categroy/third?pid=${row.id}`)
    },
    toDetail(row) {
      this.$router.push(`/categroy/add?type=2&pid=${this.pid}&id=${row.id}`)
    }
  }
}
</script>
